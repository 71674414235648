/* eslint-disable no-unused-expressions */
/* eslint-disable max-len */
import { useState } from 'react';

export default function useToken() {
  const [loading, setLoading] = useState(true);
  
  const getToken = () => {
    try {
      const tokenString = localStorage.getItem('ceflix.token')|| '';


      if (tokenString === null || tokenString === '') {
        return ''
      } else {
        const userToken = JSON.parse(tokenString);
        return userToken;
      }
    } catch (error) {
      return error;
    }
  };

  async function setSubscriptions(userToken) {
    const token = userToken.data.token;
    const req = await fetch(`${process.env.REACT_APP_API_URL}user/subscriptions`, {
      method: 'POST',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Request-Method': 'POST',
        'X-Requested-With': 'XMLHttpRequest',
        'Content-Type': 'application/json',
        'Application-Key': `${process.env.REACT_APP_API}`,
      },
      body: JSON.stringify({ token })
    });
    const res = await req.json();
    if(res.status){
    localStorage.setItem('ceflix.subscriptions', JSON.stringify(res.data))|| '';
    }
  }

  const [token, setToken] = useState(getToken());

  const saveToken = (userToken) => {
    if(userToken === ''){
      return false;
    } else {
    localStorage.setItem('ceflix.encID', JSON.stringify(userToken.data.encID))|| '';
    localStorage.setItem('ceflix.purchase_token', JSON.stringify(userToken.data.purchase_token))|| '';
    localStorage.setItem('ceflix.token', JSON.stringify(userToken.data.token))|| '';
    localStorage.setItem('ceflix.user', JSON.stringify(userToken.data.user))|| '';
    setSubscriptions(userToken);
    }
  };

  return {
    setToken: saveToken,
    token,
  };
}