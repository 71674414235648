import React, { Fragment, useState, useEffect, useRef, memo } from 'react';
import Plyr from 'plyr-react';
import 'plyr-react/plyr.css';
import Hls from 'hls.js';
import './../../assets/styles/styles.scss';
import { SignalIcon } from '@heroicons/react/20/solid';
import { capitalize } from '../../assets/scripts/script';
import {XMarkIcon} from '@heroicons/react/20/solid';
import useToken from '../../assets/scripts/useToken';

export default function PopupPlayer({data, func}) {
  const token = useToken();

  let user = '';
  try {
    user = JSON.parse(localStorage.getItem('ceflix.user') || '');
  } catch {
    user = '';
  }

  const [videoX, setVideoX] = useState(data.url);
  const [loading, setLoading] = useState(true);

  const defaultLanguage = () => {
    setVideoX(data.url);
    
  };

  const updateLanguage = (lan, id, title) => {
    setVideoX(lan);

    if(window.location.pathname === '/'){
        countView(id, title);
    }
  };

  async function countView(id, title) {
    const req = await fetch(`${process.env.REACT_APP_API_URL}countvideoview`, {
      method: 'POST',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Request-Method': 'POST',
        'X-Requested-With': 'XMLHttpRequest',
        'Content-Type': 'application/json',
        'Application-Key': `${process.env.REACT_APP_API}`,
        'X-TOKEN': `${token.token}`
      },
      body: JSON.stringify({
        email: user.email,
        video: id,
        language: title
      })
    });
    const res = await req.json();
  }

  const ref = useRef(null);
  const plyrProps = {
    source: {
      type: 'video',
      title: data.title
    },
    options: {
      autoplay: true,
      playsinline: true,
      disableContextMenu: true,
      speed: { selected: 1, options: [0.75, 1, 1.25] },
      controls: [
        'play-large', // The large play button in the center
        // 'rewind', // Rewind by the seek time (default 10 seconds)
        'play', // Play/pause playback
        // 'fast-forward', // Fast forward by the seek time (default 10 seconds)
        'progress', // The progress bar and scrubber for playback and buffering
        'current-time', // The current time of playback
        'duration', // The full duration of the media
        'mute', // Toggle mute
        'volume', // Volume control
        'captions', // Toggle captions
        'settings', // Settings menu
        'pip', // Picture-in-picture (currently Safari only)
        'airplay', // Airplay (currently Safari only)
        // 'download', // Show a download button with a link to either the current source or a custom URL you specify in your options
        'fullscreen' // Toggle fullscreen
      ]
    }
  };

  useEffect(() => {
    const loadVideo = async () => {
      const video = document.getElementById('plyr') || '';
      const hls = new Hls();
      hls.loadSource(videoX);
      hls.attachMedia(video);
    };
    loadVideo();
  }, [videoX]);

  return (
    <>
    <div className="bg-popup">
      <div className="bg-neutral-900 text-white rounded-xl p-4 centered-axis-xy w-full max-w-3xl px-6">
        <div className='flex flex-row items-center justify-between mb-3'>
        <h1 className="text-lg font-bold flex flex-row">
          {' '}
          <span className="mr-2 text-xs text-red-500 flex flex-row items-center gap-x-2 text-white font-semibold uppercase transition bg-red-700 px-2 rounded-md">
            <SignalIcon className="h-6 w-6 text-white" aria-hidden="true" />
            Live
          </span>
          {data.title}
        </h1>

        <div className="flex items-center pl-3">
              <XMarkIcon
                id="clearBtn"
                className="h-5 w-5 cursor-pointer text-white"
                aria-hidden="true"
                onClick={() => func(false)}
              />
            </div>
            </div>
        <Plyr id="plyr" source={plyrProps.source} options={plyrProps.options} ref={ref} />

        {data.languages.length >= 1 && (
          <div className="pt-2 mt-2 items-center bg-neutral-800 rounded-md p-2">
            <>
              <p className="text-xs mb-2 font-semibold">Available Languages</p>
              <div className="space-x-2">
                <button
                  type="button"
                  onClick={() => defaultLanguage()}
                  className="transition inline-block text-center rounded text-white bg-neutral-600 hover:bg-neutral-700 py-1 px-1.5 mb-2 text-xs">
                  Default
                </button>
                {data.languages.map((item) => (
                  <button
                    type="button"
                    key={item.id}
                    onClick={() => updateLanguage(item.url, item.video_id, item.translation)}
                    className="bg-neutral-600 hover:bg-neutral-700 transition inline-block text-center rounded text-white py-1 px-1.5 mb-2 text-xs">
                    {capitalize(item.translation)}
                  </button>
                ))}
              </div>
            </>
          </div>
        )}
      </div>
    </div>
    </>
  );
}
