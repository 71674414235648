/* eslint-disable jsx-a11y/no-autofocus */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable max-len */
import React, { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom'; 
import PropTypes from 'prop-types';
import { XMarkIcon, MagnifyingGlassIcon } from '@heroicons/react/24/outline';
// import { MagnifyingGlassIcon } from '@heroicons/react/20/solid';

export function SearchBar() {
const navigate = useNavigate();
const [searchParams] = useSearchParams();
  const search = searchParams.get('search_query');

  const [wordEntered, setWordEntered] = useState(search !== null ? search : '');

  const clearInput = () => {
    setWordEntered('');
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      // router.push(`/results?search_query=${wordEntered}`);
      if (wordEntered !== '') {
        window.location.replace(`/results?search_query=${wordEntered}`);
      }
    }
  };

  const searchInput = () => {
    if (wordEntered !== '') {
      window.location.replace(`/results?search_query=${wordEntered}`);
    }
  }

  const query = () => {
    navigate(`/results?search_query=${wordEntered}`);
  };
  return (
    <div className="w-full flex justify-right">
      <label htmlFor="search" className="sr-only">
        Search
      </label>
      <div className="relative w-full max-w-[80rem]">
        <div className="flex flex-row items-center">
          <div className="w-full relative">
            <div className="w-full relative bg-ceflix rounded-lg focus:bg-white focus:text-gray-900 overflow-hidden">
              <input
                type="text"
                className="block w-full mr-2 pr-[6rem] border-0 py-1.5 pr-3 font-meduim bg-transparent text-neutral-300 placeholder:text-neutral-300 focus:ring-0 sm:text-sm sm:leading-6"
                onChange={(e) => setWordEntered(e.target.value)}
                onKeyDown={handleKeyDown}
                placeholder="Type To Search"
                value={wordEntered}
              />
              <div className="absolute left-[0.8rem] top-[0.5rem]"></div>
              <div
                className="absolute right-[.25rem] top-[0.25rem] w-10 text-white ml-2 py-1 px-4 text-xs">
                <MagnifyingGlassIcon
                  id="clearBtn"
                  className="h-5 w-5 cursor-pointer text-white"
                  aria-hidden="true"
                  onClick={() => {clearInput(); searchInput();}}
                />
              </div>
            </div>
            {wordEntered.length > 0 && (
              <div className="absolute inset-y-0 right-[5rem] flex items-center ml-3 pr-3">
                <XMarkIcon
                  id="clearBtn"
                  className="h-5 w-5 cursor-pointer text-white"
                  aria-hidden="true"
                  onClick={clearInput}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
SearchBar.propTypes = {
  placeholder: PropTypes.string.isRequired
};
