import React, { Fragment, useEffect, useState } from 'react';
import { Routes, useLocation } from 'react-router-dom';
import TopBarProgress from 'react-topbar-progress-indicator';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import { XMarkIcon } from '@heroicons/react/20/solid'
import { useClose, useCloseUpdate } from './context';

TopBarProgress.config({
  barColors: {
    0: '#d32f2d',
    '1.0': '#d32f2d'
  },
  shadowBlur: 0,
  className: 'topbar',
});

function CustomSwitch({ children }) {
  const [progress, setProgress] = useState(false);
  const [prevLoc, setPrevLoc] = useState('');
  const location = useLocation();
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [hide, setHide] = useState(false);
  const [menu, setMenu] = useState(false);
  const [search, setSearch] = useState(false);
  const [btn, setBtn] = useState(false);
  const [user, setUser] = useState(false);
  const [loading, setLoading] = useState(true);
  const [subscriptionsLen, setSubscriptionsLen] = useState(4);
  const [toggle, setToggle] = useState(true);
  const path = useLocation();
  const pathname = path.pathname;

  const close = useClose();
  const closeUpdate = useCloseUpdate();

  const [subscriptions, setSubscriptions] = useState([
    { channel: 'Loveworld USA', id: '2104', url: '/images/subscriptions/lw-worship.png' },
    { channel: 'Loveworld UK', id: '870', url: '/images/subscriptions/lw-uk.jpg' },
    { channel: 'LXP-PRIME SPORTS', id: '2936', url: '/images/subscriptions/lw-xp.png' },
    { channel: 'SPORTS EXTRA', id: '2116', url: '/images/subscriptions/sports-extra.png' }
  ]);

  const listenScrollEvent = (event) => {
    if (window.scrollY < 33) {
      setHide(false);
    }
    if (window.scrollY > 30) {
      setHide(true);
    }
  };

  useEffect(() => {
    setUser(JSON.parse(localStorage.getItem('ceflix.user'))|| '');
    setLoading(false);
    window.addEventListener('scroll', listenScrollEvent);
    return () => window.removeEventListener('scroll', listenScrollEvent);

  }, []);


  useEffect(() => {
    setPrevLoc(location.pathname);
    setProgress(true);
    if (location.pathname === prevLoc) {
      setPrevLoc('');
    }
  }, [location]);

  useEffect(() => {
    setProgress(false);
  }, [prevLoc]);

  return (
    <>
      {progress && <TopBarProgress />}

{close &&
    <div className="z-[999999] pointer-events-none fixed inset-x-0 bottom-0 px-6 pb-6">
      <div className="pointer-events-auto ml-auto max-w-xl rounded-xl bg-white p-6 shadow-lg ring-1 ring-gray-900/10">
      <button onClick={() => closeUpdate(false)} type="button" className="-m-3 float-right flex-none p-3 focus-visible:outline-offset-[-4px]">
            <span className="sr-only">Dismiss</span>
            <XMarkIcon className="h-5 w-5 text-black" aria-hidden="true" />
          </button>
        <p className="text-sm leading-6 text-gray-900">
        Your video is currently processing and will be available shortly.
        </p>
      </div>
    </div>
}

      <Routes>
        {children}
        </Routes>
    </>
  );
}

CustomSwitch.propTypes = {
  children: PropTypes.arrayOf(ReactRouterPropTypes.route).isRequired,
};

export default CustomSwitch;
