/* eslint-disable react-hooks/rules-of-hooks */
import { useState } from 'react';

const translationState = () => {
    const [translations, setTranslations] = useState([
        {
          code: 'en',
          title: 'English',
          countryCode: 'gb',
        },
        {
          code: 'fr',
          title: 'Français',
          countryCode: 'fr',
        },
        {
          code: 'es',
          title: 'Español',
          countryCode: 'es',
        },
        {
          code: 'de',
          title: 'Deutsch',
          countryCode: 'de',
        },
      ]);

    const translationActions = (action) => {
        const { type, payload } = action;
        switch (type) {
            case 'setState':
                return setTranslations(payload);
            default:
                return translations;
        }
    };
    return { translations, translationActions };
};

export default translationState;
