import { Disclosure } from '@headlessui/react'
import { MinusSmallIcon, PlusSmallIcon } from '@heroicons/react/24/outline'


  const faqs = [
    {
      "question": "What is Ceflix.org?",
      "answer": "Ceflix.org is a platform dedicated to providing a wide range of Christian content, including videos, music, and articles, aimed at promoting spiritual growth and community engagement."
    },
    {
      "question": "How do I create an account on Ceflix.org?",
      "answer": "To create an account on Ceflix.org, click on the 'Sign Up' button on the homepage, fill in the required information, and follow the instructions to complete the registration process."
    },
    {
      "question": "Is Ceflix.org free to use?",
      "answer": "Yes, Ceflix.org is a free platform for users. However, there might be premium content or features that require a subscription or one-time payment. Check the specific content for more details."
    },
    {
      "question": "Can I upload my own Christian content to Ceflix.org?",
      "answer": "Yes, you can contribute to the community by uploading your Christian videos, music, or articles. Simply log in to your account, navigate to the 'Upload' section, and follow the guidelines provided to share your content."
    },
    {
      "question": "How can I search for specific content on Ceflix.org?",
      "answer": "Use the search bar located at the top of the homepage to enter keywords related to the content you are looking for. You can also explore different categories and tags to find specific types of Christian content."
    },
    {
      "question": "Are the videos on Ceflix.org available for download?",
      "answer": "Currently, Ceflix.org does not support direct downloading of videos. However, you can save your favorite content to your playlist for easy access later. Stay tuned for updates and new features that may include download options."
    },
    {
      "question": "How can I contact support for assistance?",
      "answer": "If you encounter any issues or have questions, you can reach out to our support team through the 'Contact Us' page. Fill in the required information and describe your concern, and our team will get back to you as soon as possible."
    },
    {
      "question": "Is there a mobile app for Ceflix.org?",
      "answer": "Yes, Ceflix.org has a mobile app available for both Android and iOS devices. You can download the app from the LoveWorld App Store and respective app stores to enjoy Christian content on the go."
    }
  ];


export default function FAQs() {
  return (
    <div className="">
      <div className="mx-auto px-4 py-8">
        <div className="mx-auto max-w-screen-xl divide-y divide-white/10">
          <h2 className="text-2xl font-bold leading-10 tracking-tight text-white">Frequently asked questions</h2>
          <dl className="mt-10 space-y-6 divide-y divide-white/10">
            {faqs.map((faq) => (
              <Disclosure as="div" key={faq.question} className="pt-6">
                {({ open }) => (
                  <>
                    <dt>
                      <Disclosure.Button className="flex w-full items-start justify-between text-left text-white">
                        <span className="text-base font-semibold leading-7">{faq.question}</span>
                        <span className="ml-6 flex h-7 items-center">
                          {open ? (
                            <MinusSmallIcon className="h-6 w-6" aria-hidden="true" />
                          ) : (
                            <PlusSmallIcon className="h-6 w-6" aria-hidden="true" />
                          )}
                        </span>
                      </Disclosure.Button>
                    </dt>
                    <Disclosure.Panel as="dd" className="mt-2 pr-12">
                      <p className="text-base leading-7 text-gray-300">{faq.answer}</p>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            ))}
          </dl>
        </div>
      </div>
    </div>
  )
}
