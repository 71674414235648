import { useState, createContext, useContext, useEffect } from 'react';

let token = '';
try {
  token = JSON.parse(localStorage.getItem('ceflix.token') || '');
} catch {
  token = '';
}

const SidebarContext = createContext();
const SidebarUpdateContext = createContext();

const CloseContext = createContext();
const CloseUpdateContext = createContext();

const SidebarVideoContext = createContext();
const SidebarVideoUpdateContext = createContext();
const SidebarMobileContext = createContext();
const SidebarMobileUpdateContext = createContext();
const TokenContext = createContext();
const TheatreContext = createContext();
const TheatreUpdateContext = createContext();

export function useSidebar() {
  return useContext(SidebarContext);
}

export function useSidebarUpdate() {
  return useContext(SidebarUpdateContext);
}

export function useClose() {
  return useContext(CloseContext);
}

export function useCloseUpdate() {
  return useContext(CloseUpdateContext);
}

export function useSidebarMobile() {
  return useContext(SidebarMobileContext);
}

export function useSidebarMobileUpdate() {
  return useContext(SidebarMobileUpdateContext);
}

export function useSidebarVideo() {
  return useContext(SidebarVideoContext);
}

export function useSidebarVideoUpdate() {
  return useContext(SidebarVideoUpdateContext);
}

export function useTheatre() {
  return useContext(TheatreContext);
}

export function useTheatreUpdate() {
  return useContext(TheatreUpdateContext);
}

export function useToken() {
  return useContext(TokenContext);
}

export function ContextProvider({ children }) {
  const [isDesktop, setDesktop] = useState(window.innerWidth > 1024);

  useEffect(() => {
    window.addEventListener('resize', updateMedia);
    return () => window.removeEventListener('resize', updateMedia);
  });

  const updateMedia = () => {
    setDesktop(window.innerWidth > 1024);
  };

  const [sidebar, setSidebar] = useState(false);
  const [sidebarVideo, setSidebarVideo] = useState(true);
  const [sidebarMobile, setSidebarMobile] = useState(true);
  const [theatre, setTheatre] = useState(true);
  const [close, setClose] = useState(false);

  const toggleClose = (obj) => {
    setClose(obj);
  };

  const toggleSidebarMobile = (obj) => {
    setSidebarMobile(obj);
  };

  const toggleSidebarVideo = (obj) => {
    setSidebarVideo(obj);
  };

  const toggleSidebar = (obj) => {
    setSidebar(obj);
  };

  const toggleTheatre = (obj) => {
    setTheatre(obj);
  };

  return (
    <CloseContext.Provider value={close}>
      <CloseUpdateContext.Provider value={toggleClose}>
        <SidebarContext.Provider value={sidebar}>
          <SidebarUpdateContext.Provider value={toggleSidebar}>
            <SidebarMobileContext.Provider value={sidebarMobile}>
              <SidebarMobileUpdateContext.Provider value={toggleSidebarMobile}>
                <SidebarVideoContext.Provider value={sidebarVideo}>
                  <SidebarVideoUpdateContext.Provider value={toggleSidebarVideo}>
                    <TheatreContext.Provider value={theatre}>
                      <TheatreUpdateContext.Provider value={toggleTheatre}>
                        <TokenContext.Provider value={token}>{children}</TokenContext.Provider>
                      </TheatreUpdateContext.Provider>
                    </TheatreContext.Provider>
                  </SidebarVideoUpdateContext.Provider>
                </SidebarVideoContext.Provider>
              </SidebarMobileUpdateContext.Provider>
            </SidebarMobileContext.Provider>
          </SidebarUpdateContext.Provider>
        </SidebarContext.Provider>
      </CloseUpdateContext.Provider>
    </CloseContext.Provider>
  );
}
