/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/no-redundant-roles */
import React, { Fragment, useState, useEffect } from 'react';
import { Dialog, Menu, Transition } from '@headlessui/react';
import { Logout, truncate, capitalize } from '../../assets/scripts/script.js';
import { useLocation, useNavigate } from 'react-router-dom';
import { SearchBar } from '../misc/SearchBar.jsx';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import {
  useSidebar,
  useSidebarUpdate,
  useSidebarVideo,
  useSidebarVideoUpdate,
  useSidebarMobile,
  useSidebarMobileUpdate
} from '../misc/context.jsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCompass,
  faHeart,
  faFolder,
  faCirclePlay,
  faListAlt
} from '@fortawesome/free-regular-svg-icons';
import {
  faFire,
  faFilm,
  faClockRotateLeft,
  faVideo,
  faTv
} from '@fortawesome/free-solid-svg-icons';

import {
  Bars3Icon,
  HeartIcon,
  CpuChipIcon,
  MusicalNoteIcon,
  RectangleStackIcon,
  BanknotesIcon,
  TrophyIcon,
  MegaphoneIcon,
  XMarkIcon,
  SparklesIcon,
  PaintBrushIcon,
  AcademicCapIcon,
  MagnifyingGlassIcon,
  Cog6ToothIcon,
  InformationCircleIcon,
  FilmIcon,
  VideoCameraIcon,
  ArchiveBoxIcon,
  QuestionMarkCircleIcon
} from '@heroicons/react/24/outline';
// import Link from 'next/link';
import { Link } from 'react-router-dom';

import { UserCircleIcon, HomeIcon, GlobeAltIcon } from '@heroicons/react/24/solid';

const navigation = [
  { name: 'Explore', href: '/', icon: faCompass, current: false },
  { name: 'Trending', href: '/trending', icon: faFire, current: false },
  { name: 'CeClips', href: '/ceclips', icon: faFilm, current: false },
  { name: 'Subscriptions', href: '/subscriptions', icon: faCirclePlay, current: false }
];

const navigation2 = [
  { name: 'History', href: '/history', icon: faClockRotateLeft, current: false },
  { name: 'Channels', href: '/channels', icon: faTv, current: false },
  { name: 'Playlists ', href: '/playlists', icon: faListAlt, current: false },
  { name: 'Liked Videos', href: '/likedvideos', icon: faHeart, current: false },
  { name: 'Your Videos', href: '/studio/content', icon: faVideo, current: false }
];

const more = [
  { name: 'Settings', href: '/studio/settings', icon: Cog6ToothIcon, current: false },
  { name: 'FAQs', href: '/faqs', icon: QuestionMarkCircleIcon, current: false }
  // { name: 'Send feedback', href: '/history', icon: InformationCircleIcon, current: false }
];

const featured = [
  { name: 'Featured', href: '#', icon: SparklesIcon, current: false },
  { name: 'Artists', href: '#', icon: PaintBrushIcon, current: false },
  { name: 'Education', href: '#', icon: AcademicCapIcon, current: false },
  { name: 'Lifestyle', href: '#', icon: RectangleStackIcon, current: false },
  { name: 'Music', href: '#', icon: MusicalNoteIcon, current: false },
  { name: 'News & Politics', href: '#', icon: MegaphoneIcon, current: false },
  { name: 'Gaming', href: '#', icon: TrophyIcon, current: false },
  { name: 'Tech', href: '#', icon: CpuChipIcon, current: false },
  { name: 'Finance', href: '#', icon: BanknotesIcon, current: false }
];

const userNavigation = [
  { name: 'Ceflix Studio', href: '/studio' },
  { name: 'Account Settings', href: '/studio/settings' }
];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const Navigation = ({ children }) => {
  const sidebar = useSidebar();
  const toggleSidebar = useSidebarUpdate();

  const sidebarVideo = useSidebarVideo();
  const toggleSidebarVideo = useSidebarVideoUpdate();

  const sidebarMobile = useSidebarMobile();
  const toggleSidebarMobile = useSidebarMobileUpdate();

  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [sidebarMobileOpen, setSidebarMobileOpen] = useState(false);
  const [hide, setHide] = useState(false);
  const [menu, setMenu] = useState(false);
  const [search, setSearch] = useState(false);
  const [btn, setBtn] = useState(false);
  const [user, setUser] = useState(false);
  const [loading, setLoading] = useState(true);
  const [subscriptionsLen, setSubscriptionsLen] = useState(4);
  const [toggle, setToggle] = useState(true);
  const path = useLocation();
  const pathname = path.pathname;

  const [isDesktop, setDesktop] = useState(window.innerWidth > 959);

  useEffect(() => {
    window.addEventListener('resize', updateMedia);
    return () => window.removeEventListener('resize', updateMedia);
  });

  const updateMedia = () => {
    setDesktop(window.innerWidth > 959);
  };

  const [subscriptions, setSubscriptions] = useState([
    { channel: 'Loveworld USA', id: '2104', url: '/images/subscriptions/lw-worship.png' },
    { channel: 'Loveworld UK', id: '870', url: '/images/subscriptions/lw-uk.jpg' },
    { channel: 'LXP-PRIME SPORTS', id: '2936', url: '/images/subscriptions/lw-xp.png' },
    { channel: 'SPORTS EXTRA', id: '2116', url: '/images/subscriptions/sports-extra.png' }
  ]);

  const toggleSubscriptions = () => {
    if (toggle) {
      setSubscriptionsLen(JSON.parse(localStorage.getItem('ceflix.subscriptions')).length || '');
    } else {
      setSubscriptionsLen(4);
    }
    setToggle(!toggle);
  };

  const listenScrollEvent = (event) => {
    if (window.scrollY < 33) {
      setHide(false);
    }
    if (window.scrollY > 30) {
      setHide(true);
    }
  };

  useEffect(() => {
    // if (window.innerWidth < 1024) {
    //   toggleSidebar(true);
    // }
    setUser(JSON.parse(localStorage.getItem('ceflix.user')) || '');
    setLoading(false);
    if (JSON.parse(localStorage.getItem('ceflix.subscriptions')) !== null) {
      setSubscriptions(JSON.parse(localStorage.getItem('ceflix.subscriptions')) || []);
    }

    window.addEventListener('scroll', listenScrollEvent);
    return () => window.removeEventListener('scroll', listenScrollEvent);
  }, []);

  return (
    <>
      {isDesktop && (
        <div className="hidden lg:block">
          <div
            className={`${pathname.includes('watch') || pathname.includes('playlist/play') ? 'lg:ml-0' : ''}`}>
            <Transition.Root show={sidebarOpen} as={Fragment}>
              <Dialog as="div" className="relative z-50" onClose={setSidebarOpen}>
                <Transition.Child
                  as={Fragment}
                  enter="transition-opacity ease-linear duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="transition-opacity ease-linear duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0">
                  <div className="fixed inset-0 bg-neutral-900/80" />
                </Transition.Child>

                <div className="fixed inset-0 flex">
                  <Transition.Child
                    as={Fragment}
                    enter="transition ease-in-out duration-300 transform"
                    enterFrom="-translate-x-full"
                    enterTo="translate-x-0"
                    leave="transition ease-in-out duration-300 transform"
                    leaveFrom="translate-x-0"
                    leaveTo="-translate-x-full">
                    <Dialog.Panel className="relative mr-16 flex w-full max-w-[18rem] flex-1">
                      <Transition.Child
                        as={Fragment}
                        enter="ease-in-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in-out duration-300"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0">
                        <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                          <button
                            type="button"
                            className="-m-2.5 p-2.5"
                            onClick={() => setSidebarOpen(false)}>
                            <span className="sr-only">Close sidebar</span>
                            <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
                          </button>
                        </div>
                      </Transition.Child>
                      {/* Sidebar component, swap this element with another sidebar if you like */}
                      <div className="flex grow flex-col gap-y-5 z-50 overflow-y-auto px-6 pb-4 ring-1 ring-white/10">
                        <Link to="/" className="flex mt-4 h-14 shrink-0 items-center">
                          <LazyLoadImage
                            src="/logo-light.png"
                            alt="Ceflix Logo 2"
                            className="w-24 md:w-32"
                          />
                        </Link>
                        <nav className="flex flex-1 flex-col">
                          <div className="flex flex-1 flex-col gap-y-7">
                            <ul role="list" className="-mx-2">
                              <li className="divide-y divide-neutral-800">
                                <div className="pb-2 space-y-1">
                                  {navigation.map((item) => (
                                    <div key={item.name}>
                                      <Link
                                        to={item.href}
                                        className={`${
                                          pathname.includes(item.href)
                                            ? 'bg-[#111]'
                                            : 'hover:bg-neutral-800 rounded-md'
                                        }
                            'group text-gray-200 text-sm flex flex-row gap-x-5 rounded-md p-1.5 leading-6 font-semibold'
                          `}>
                                        <FontAwesomeIcon
                                          icon={faCompass}
                                          className={`${
                                            pathname.includes(item.href)
                                              ? 'bg-[#111]'
                                              : 'hover:bg-neutral-800 rounded-md'
                                          } h-6 w-6`}
                                          aria-hidden="true"
                                        />
                                        {item.name}
                                      </Link>
                                    </div>
                                  ))}
                                </div>
                              </li>
                            </ul>
                          </div>
                        </nav>
                      </div>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </Dialog>
            </Transition.Root>

            {/* Watch page navigation */}
            {(pathname.includes('watch') || pathname.includes('playlist/play')) && (
              <div
                className={`divide-y ${
                  sidebarVideo ? 'w-[5rem] hidden' : 'w-[16rem]'
                } bg-ceflix h-full ease-linear z-[60] border-neutral-200/5 lg:fixed lg:inset-y-0 lg:flex-col fixed block`}>
                {/* desktop Sidebar component, swap this element with another sidebar if you like */}
                <div className="flex grow nav-wrapper flex-col gap-y-5 px-5 pt-1">
                  <nav
                    className={`${
                      !sidebarVideo ? 'w-[16rem]' : 'w-[5rem]'
                    } flex nav-sidebar h-full left-0 flex-1 flex-col pt-1`}>
                    <div className="flex flex-1 text-center items-center flex-col mt-1">
                      <div
                        className={`${sidebarVideo ? 'justify-center' : ''} flex flex-row w-full`}>
                        <button
                          type="button"
                          className={`${sidebarVideo ? '' : 'px-6'} p-2 rounded-full`}
                          onClick={() => toggleSidebarVideo(!sidebarVideo)}>
                          <span className="sr-only">Open sidebar</span>
                          <Bars3Icon className="text-white h-6 w-6" aria-hidden="true" />
                        </button>
                        <Link to="/">
                          <LazyLoadImage
                            src="/logo-light.png"
                            className={`${!sidebarVideo ? 'black' : 'hidden'} w-24`}
                            alt="Ceflix Logo 3"
                          />
                        </Link>
                      </div>
                      <div className={`w-full space-y-4 mt-6 ${!sidebarVideo && 'px-3'}`}>
                        <div className="divide-y-2 divide-neutral-200/5">
                          <ul
                            role="list"
                            className={`${sidebarVideo ? 'p-[.4rem] space-y-4' : 'pr-4 w-full'} mb-4`}>
                            {navigation.map((item) => (
                              <li className="" key={item.name}>
                                <Link
                                  to={item.href}
                                  onClick={() => toggleSidebarVideo(false)}
                                  className={`w-auto ${
                                    (
                                      item.href !== '/'
                                        ? pathname.includes(item.href) && item.href !== '/'
                                        : pathname === '/' && true
                                    )
                                      ? 'text-white'
                                      : 'text-white'
                                  }
                              group flex  ${
                                sidebarVideo
                                  ? 'w-24 flex-col text-xxxs items-center rounded-md'
                                  : 'flex-row items-center font-medium rounded-lg tracking-wider'
                              } hover:bg-neutral-700 transition p-3
                          `}>
                                  <FontAwesomeIcon
                                    icon={item.icon}
                                    className={`${pathname === item.href && 'text-red-600'} text-xl`}
                                    aria-hidden="true"
                                  />
                                  <span
                                    className={`text-xs ${sidebarVideo ? 'text-xxxs mt-2' : 'ml-4'}`}>
                                    {item.name}
                                  </span>
                                </Link>
                              </li>
                            ))}
                            {sidebarVideo && (
                              <li className="" key="library">
                                <Link
                                  to="/library"
                                  className={`w-auto text-white
                              group flex  ${
                                sidebarVideo
                                  ? 'w-24 flex-col text-xxxs items-center rounded-md'
                                  : 'flex-row items-center font-medium rounded-lg tracking-wider'
                              } hover:bg-neutral-700 transition p-3
                          `}>
                                  <FontAwesomeIcon
                                    icon={faFolder}
                                    className={`${pathname === 'library' && 'text-red-600'} text-xl`}
                                    aria-hidden="true"
                                  />
                                  <span
                                    className={`text-xs ${sidebarVideo ? 'text-xxxs mt-2' : 'ml-4'}`}>
                                    Library
                                  </span>
                                </Link>
                              </li>
                            )}
                          </ul>

                          {!sidebarVideo && (
                            <>
                              <ul
                                role="list"
                                className={`${sidebarVideo ? 'p-[.4rem] space-y-7' : 'pr-4 w-full'} pt-2`}>
                                {navigation2.map((item) => (
                                  <li className="" key={item.name}>
                                    <Link
                                      to={item.href}
                                      onClick={() => toggleSidebarVideo(false)}
                                      className={`w-auto ${
                                        (
                                          item.href !== '/'
                                            ? pathname.includes(item.href) && item.href !== '/'
                                            : pathname === '/' && true
                                        )
                                          ? 'text-white'
                                          : 'text-white'
                                      }
                              group flex p-3  ${
                                sidebarVideo
                                  ? 'w-24 flex-col text-xxxs items-center rounded-md'
                                  : 'flex-row items-center font-medium rounded-lg tracking-wider hover:bg-neutral-700 transition'
                              }
                          `}>
                                      <FontAwesomeIcon
                                        icon={item.icon}
                                        className={`${
                                          pathname.includes(item.href) && 'text-red-600'
                                        } text-md mr-4`}
                                        aria-hidden="true"
                                      />
                                      <span className="text-xs">{item.name}</span>
                                    </Link>
                                  </li>
                                ))}
                              </ul>
                            </>
                          )}
                        </div>

                        {!sidebarVideo && (
                          <>
                            <h1 className="text-white text-xxs uppercase text-left pl-3">
                              Subscriptions
                            </h1>
                            <ul
                              role="list"
                              className={`${sidebarVideo ? 'p-[.4rem] space-y-7' : 'pr-4 w-full'}`}>
                              {subscriptions.slice(0, subscriptionsLen).map((item) => (
                                <li className="" key={item.channel}>
                                  <Link
                                    onClick={() => toggleSidebarVideo(false)}
                                    to={`/channel/${item.id}`}
                                    className={`w-auto text-white group flex p-3 ${
                                      sidebarVideo
                                        ? 'w-24 flex-col text-xxxs items-center rounded-md'
                                        : 'flex-row items-center font-medium rounded-lg tracking-wider hover:bg-neutral-700 transition'
                                    }
                          `}>
                                    <LazyLoadImage
                                      src={item.url}
                                      className="object-cover w-[1.4rem] h-[1.4rem] mr-2 rounded-full"
                                    />
                                    <span className="text-xs">
                                      {truncate(capitalize(item.channel), 14)}
                                    </span>
                                  </Link>
                                </li>
                              ))}
                              {(JSON.parse(localStorage.getItem('ceflix.subscriptions')) || '') && (
                                <button
                                  className="w-full px-3 text-left text-xs py-2 text-white font-medium rounded-md tracking-wider hover:bg-neutral-700 transition"
                                  onClick={() => toggleSubscriptions()}>
                                  {toggle ? 'Show More' : 'Show Less'}
                                </button>
                              )}
                            </ul>
                          </>
                        )}

                        {!sidebarVideo && (
                          <>
                            <h1 className="text-white text-xxs uppercase text-left pl-3">
                              More from ceflix
                            </h1>
                            <ul
                              role="list"
                              className={`${sidebarVideo ? 'p-[.4rem] space-y-7' : 'pr-4 w-full'} mb-4`}>
                              {more.map((item) => (
                                <li className="" key={item.name}>
                                  <Link
                                    onClick={() => toggleSidebarVideo(false)}
                                    to={item.href}
                                    className={`w-auto ${
                                      (
                                        item.href !== '/'
                                          ? pathname.includes(item.href) && item.href !== '/'
                                          : pathname === '/' && true
                                      )
                                        ? 'text-white'
                                        : 'text-white'
                                    }
                              group flex p-3  ${
                                sidebarVideo
                                  ? 'w-24 flex-col text-xxxs items-center rounded-full'
                                  : 'flex-row items-center font-medium rounded-lg tracking-wider hover:bg-neutral-700 transition'
                              }
                          `}>
                                    <item.icon className="w-6 text-white mr-2" aria-hidden="true" />
                                    {/* <FontAwesomeIcon icon={item.icon} className={`${pathname.includes(item.href)
                                          && "text-red-600"
                                        } text-lg mr-4`} aria-hidden="true"  aria-hidden="true" /> */}
                                    <span className="text-xs">{item.name}</span>
                                  </Link>
                                </li>
                              ))}
                            </ul>
                          </>
                        )}
                      </div>
                    </div>
                  </nav>
                </div>
              </div>
            )}

            {/* Static sidebar for desktop */}
            {!(pathname.includes('watch') || pathname.includes('playlist/play')) && (
              <div
                className={`divide-y ${
                  sidebar ? 'w-[5rem] hidden' : 'w-[16rem]'
                } bg-ceflix h-full ease-linear z-[60]  border-neutral-200/5 lg:fixed lg:inset-y-0 lg:flex-col ${
                  (pathname.includes('watch') || pathname.includes('playlist/play')) && sidebar
                    ? 'lg:hidden'
                    : 'fixed block lg:flex'
                }`}>
                {/* desktop Sidebar component, swap this element with another sidebar if you like */}
                <div className="flex grow nav-wrapper flex-col gap-y-5 px-5 pt-1">
                  <nav
                    className={`${
                      !sidebar ? 'w-[16rem]' : 'w-[5rem]'
                    } flex nav-sidebar h-full left-0 flex-1 flex-col pt-1`}>
                    <div className="flex flex-1 text-center items-center flex-col mt-1">
                      <div className={`${sidebar ? 'justify-center' : ''} flex flex-row w-full`}>
                        <button
                          type="button"
                          className={`${sidebar ? '' : 'px-6'} p-2 rounded-full`}
                          onClick={() => toggleSidebar(!sidebar)}>
                          <span className="sr-only">Open sidebar</span>
                          <Bars3Icon className="text-white h-6 w-6" aria-hidden="true" />
                        </button>
                        <Link to="/">
                          <LazyLoadImage
                            src="/logo-light.png"
                            className={`${!sidebar ? 'black' : 'hidden'} w-24`}
                            alt="Ceflix Logo 4"
                          />
                        </Link>
                      </div>
                      <div className={`w-full space-y-4 mt-6 ${!sidebar && 'px-3'}`}>
                        <div className="divide-y-2 divide-neutral-200/5">
                          <ul
                            role="list"
                            className={`${sidebar ? 'p-[.4rem] space-y-4' : 'pr-4 w-full'} mb-4`}>
                            {navigation.map((item) => (
                              <li className="" key={item.name}>
                                <Link
                                  to={item.href}
                                  onClick={() => toggleSidebarMobile(!sidebarMobile)}
                                  className={`w-auto ${
                                    (
                                      item.href !== '/'
                                        ? pathname.includes(item.href) && item.href !== '/'
                                        : pathname === '/' && true
                                    )
                                      ? 'text-white'
                                      : 'text-white'
                                  }
                              group flex  ${
                                sidebar
                                  ? 'w-24 flex-col text-xxxs items-center rounded-md'
                                  : 'flex-row items-center font-medium rounded-lg tracking-wider'
                              } hover:bg-neutral-700 transition p-3
                          `}>
                                  <FontAwesomeIcon
                                    icon={item.icon}
                                    className={`${pathname === item.href && 'text-red-600'} text-xl`}
                                    aria-hidden="true"
                                  />
                                  <span
                                    className={`text-xs ${sidebar ? 'text-xxxs mt-2' : 'ml-4'}`}>
                                    {item.name}
                                  </span>
                                </Link>
                              </li>
                            ))}
                            {sidebar && (
                              <li className="" key="library">
                                <Link
                                  to="/library"
                                  className={`w-auto text-white
                              group flex  ${
                                sidebar
                                  ? 'w-24 flex-col text-xxxs items-center rounded-md'
                                  : 'flex-row items-center font-medium rounded-lg tracking-wider'
                              } hover:bg-neutral-700 transition p-3
                          `}>
                                  <FontAwesomeIcon
                                    icon={faFolder}
                                    className={`${pathname === 'library' && 'text-red-600'} text-xl`}
                                    aria-hidden="true"
                                  />
                                  <span
                                    className={`text-xs ${sidebar ? 'text-xxxs mt-2' : 'ml-4'}`}>
                                    Library
                                  </span>
                                </Link>
                              </li>
                            )}
                          </ul>

                          {!sidebar && (
                            <>
                              <ul
                                role="list"
                                className={`${sidebar ? 'p-[.4rem] space-y-7' : 'pr-4 w-full'} pt-2`}>
                                {navigation2.map((item) => (
                                  <li className="" key={item.name}>
                                    <Link
                                      to={item.href}
                                      className={`w-auto ${
                                        (
                                          item.href !== '/'
                                            ? pathname.includes(item.href) && item.href !== '/'
                                            : pathname === '/' && true
                                        )
                                          ? 'text-white'
                                          : 'text-white'
                                      }
                              group flex p-3  ${
                                sidebar
                                  ? 'w-24 flex-col text-xxxs items-center rounded-md'
                                  : 'flex-row items-center font-medium rounded-lg tracking-wider hover:bg-neutral-700 transition'
                              }
                          `}>
                                      <FontAwesomeIcon
                                        icon={item.icon}
                                        className={`${
                                          pathname.includes(item.href) && 'text-red-600'
                                        } text-md mr-4`}
                                        aria-hidden="true"
                                      />
                                      <span className="text-xs">{item.name}</span>
                                    </Link>
                                  </li>
                                ))}
                              </ul>
                            </>
                          )}
                        </div>

                        {!sidebar && (
                          <>
                            <h1 className="text-white text-xxs uppercase text-left pl-3">
                              Subscriptions
                            </h1>
                            <ul
                              role="list"
                              className={`${sidebar ? 'p-[.4rem] space-y-7' : 'pr-4 w-full'}`}>
                              {subscriptions.slice(0, subscriptionsLen).map((item) => (
                                <li className="" key={item.channel}>
                                  <Link
                                    to={`/channel/${item.id}`}
                                    onClick={() => toggleSidebar(false)}
                                    className={`w-auto text-white group flex p-3 ${
                                      sidebar
                                        ? 'w-24 flex-col text-xxxs items-center rounded-md'
                                        : 'flex-row items-center font-medium rounded-lg tracking-wider hover:bg-neutral-700 transition'
                                    }
                          `}>
                                    <LazyLoadImage
                                      src={item.url}
                                      className="object-cover w-[1.4rem] h-[1.4rem] mr-2 rounded-full"
                                    />
                                    <span className="text-xs">
                                      {truncate(capitalize(item.channel), 14)}
                                    </span>
                                  </Link>
                                </li>
                              ))}
                              {(JSON.parse(localStorage.getItem('ceflix.subscriptions')) || '') && (
                                <button
                                  className="w-full px-3 text-left text-xs py-2 text-white font-medium rounded-md tracking-wider hover:bg-neutral-700 transition"
                                  onClick={() => toggleSubscriptions()}>
                                  {toggle ? 'Show More' : 'Show Less'}
                                </button>
                              )}
                            </ul>
                          </>
                        )}

                        {!sidebar && (
                          <>
                            <h1 className="text-white text-xxs uppercase text-left pl-3">
                              More from ceflix
                            </h1>
                            <ul
                              role="list"
                              className={`${sidebar ? 'p-[.4rem] space-y-7' : 'pr-4 w-full'} mb-4`}>
                              {more.map((item) => (
                                <li className="" key={item.name}>
                                  <Link
                                    to={item.href}
                                    className={`w-auto ${
                                      (
                                        item.href !== '/'
                                          ? pathname.includes(item.href) && item.href !== '/'
                                          : pathname === '/' && true
                                      )
                                        ? 'text-white'
                                        : 'text-white'
                                    }
                              group flex p-3  ${
                                sidebar
                                  ? 'w-24 flex-col text-xxxs items-center rounded-full'
                                  : 'flex-row items-center font-medium rounded-lg tracking-wider hover:bg-neutral-700 transition'
                              }
                          `}>
                                    <item.icon className="w-6 text-white mr-2" aria-hidden="true" />
                                    {/* <FontAwesomeIcon icon={item.icon} className={`${pathname.includes(item.href)
                                          && "text-red-600"
                                        } text-lg mr-4`} aria-hidden="true"  aria-hidden="true" /> */}
                                    <span className="text-xs">{item.name}</span>
                                  </Link>
                                </li>
                              ))}
                            </ul>
                          </>
                        )}
                      </div>
                    </div>
                  </nav>
                </div>
              </div>
            )}

            {!(pathname.includes('watch') || pathname.includes('playlist/play')) ? (
              <div className={`${pathname.includes('ceclips') && 'h-screen overflow-hidden'}`}>
                <div
                  className={`block py-4 fixed w-full top-0 z-[50] flex h-[5rem] items-center justify-between gap-x-4 transition bg-ceflix px-5 sm:gap-x-6`}>
                  <div className={`${search ? 'hidden' : 'flex'} md:flex flex-row w-full`}>
                    <div
                      className={`flex flex-row items-center ${
                        !sidebar
                          ? 'lg:ml-[14.6rem]'
                          : !(pathname.includes('watch') || pathname.includes('playlist/play'))
                            ? 'lg:ml-[5rem]'
                            : 'lg:ml-[0rem]'
                      } gap-2 lg:gap-6 flex-1 ${!sidebar ? 'justify-end' : 'justify-between'}`}>
                      {/* Separator */}

                      {pathname.includes('watch') || pathname.includes('playlist/play') ? (
                        <div
                          className={`${!sidebarVideo && 'hidden'} flex flex-row items-center gap-4`}>
                          <div className="flex flex-row items-center space-x-4">
                            {sidebarVideo && (
                              <button
                                type="button"
                                className={`${sidebarVideo ? '' : 'px-6'} p-1 rounded-full`}
                                onClick={() => toggleSidebarVideo(!sidebarVideo)}>
                                <span className="sr-only">Open sidebar</span>
                                <Bars3Icon className="text-white h-6 w-6" aria-hidden="true" />
                              </button>
                            )}

                            <Link to="/">
                              <LazyLoadImage
                                src="/logo-light.png"
                                alt="Ceflix Logo 5"
                                className="w-24 md:w-32"
                              />
                            </Link>
                          </div>
                        </div>
                      ) : (
                        <div className={`${!sidebar && 'hidden'} flex flex-row items-center gap-4`}>
                          <div className="flex flex-row items-center space-x-4">
                            {sidebar && (
                              <button
                                type="button"
                                className={`${sidebar ? 'lg:hidden' : 'px-6'} p-1 rounded-full`}
                                onClick={() => toggleSidebar(!sidebar)}>
                                <span className="sr-only">Open sidebar</span>
                                <Bars3Icon className="text-white h-6 w-6" aria-hidden="true" />
                              </button>
                            )}

                            <Link to="/">
                              <LazyLoadImage
                                src="/logo-light.png"
                                alt="Ceflix Logo 6"
                                className="w-24 md:w-32"
                              />
                            </Link>
                          </div>
                        </div>
                      )}

                      <div className={`hidden md:block mx-4 w-full`}>
                        <SearchBar placeholder="Search" btn={setMenu} />
                      </div>

                      <div className="flex items-center justify-end w-40 lg:gap-x-6">
                        <div
                          className={`grid ${
                            user ? 'grid-cols-3 md:grid-cols-2' : 'grid-cols-1'
                          } gap-x-3 items-center`}>
                          {!loading && (
                            <>
                              <button
                                type="button"
                                onClick={() => setSearch(!search)}
                                className="md:hidden p-2.5 w-10 rounded-full text-white hover:text-gray-300">
                                <span className="sr-only">View notifications</span>
                                <MagnifyingGlassIcon className="" aria-hidden="true" />
                              </button>

                              {user ? (
                                <>
                                  <div className="">
                                    <Link to="/studio/content/upload" className="">
                                      <span className="sr-only">View notifications</span>
                                      <VideoCameraIcon
                                        className="p-2 rounded-md text-white w-10"
                                        aria-hidden="true"
                                      />
                                    </Link>
                                  </div>
                                  <Menu as="div" className="relative w-8">
                                    <Menu.Button className="flex items-center">
                                      <span className="sr-only">Open user menu</span>
                                      <LazyLoadImage
                                        className="w-9 rounded-md bg-gray-50"
                                        src={user.profile_pic === "http://d3c5pcohbexzc4.cloudfront.net/profile/avatar.png" ? '/images/avatar.png' : user.profile_pic }
                                        alt="profile pic"
                                      />
                                      {/* <span className="hidden lg:flex lg:items-center">
                        <span
                          className="ml-4 text-sm font-semibold leading-6 text-white"
                          aria-hidden="true"
                        >
                          {user.fname} {user.lname}
                        </span>
                        <ChevronDownIcon
                          className="ml-2 h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                      </span> */}
                                    </Menu.Button>
                                    <Transition
                                      as={Fragment}
                                      enter="transition ease-out duration-100"
                                      enterFrom="transform opacity-0 scale-95"
                                      enterTo="transform opacity-100 scale-100"
                                      leave="transition ease-in duration-75"
                                      leaveFrom="transform opacity-100 scale-100"
                                      leaveTo="transform opacity-0 scale-95">
                                      <Menu.Items className="absolute right-0 overflow-hidden z-10 mt-2.5 w-40 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                                        {userNavigation.map((item) => (
                                          <Menu.Item key={item.name}>
                                            {({ active }) => (
                                              <Link
                                                to={item.href}
                                                className={classNames(
                                                  active ? 'bg-gray-50' : '',
                                                  'block text-sm px-3 py-2 leading-6 text-gray-900'
                                                )}>
                                                {item.name}
                                              </Link>
                                            )}
                                          </Menu.Item>
                                        ))}
                                        <Menu.Item key="logoutBtn">
                                          {({ active }) => (
                                            <button
                                              className={classNames(
                                                active ? 'bg-gray-50' : '',
                                                'block text-left px-3 py-2 w-full text-sm leading-6 text-gray-900'
                                              )}
                                              onClick={() => Logout()}>
                                              Log Out
                                            </button>
                                          )}
                                        </Menu.Item>
                                      </Menu.Items>
                                    </Transition>
                                  </Menu>
                                </>
                              ) : (
                                <div className="flex space-x-4 text-xs">
                                  <Link
                                    to="/login"
                                    className="flex flex-row whitespace-nowrap items-center gap-x-2 text-white font-bold uppercase transition bg-red-700 hover:bg-red-600 px-3 py-2 rounded-md">
                                    <UserCircleIcon
                                      className="h-6 w-6 text-white"
                                      aria-hidden="true"
                                    />
                                    Sign In
                                  </Link>
                                  {/* <Link
                        to="/signup"
                        className="hidden lg:block bg-red-500 px-7 py-2 rounded-md"
                      >
                        Sign Up
                      </Link> */}
                                </div>
                              )}
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className={`${
                      search ? 'flex flex-row' : 'hidden'
                    } w-full gap-2 items-center md:hidden`}>
                    <button
                      type="button"
                      onClick={() => setSearch(!search)}
                      className={`${
                        search && 'block'
                      } md:hidden flex-1 p-2.5 rounded-full text-white hover:text-gray-300`}>
                      <span className="sr-only">View notifications</span>
                      <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                    </button>
                    <SearchBar placeholder="Search" btn={setMenu} />
                  </div>
                </div>

                <main
                  className={`${
                    pathname.includes('watch') || pathname.includes('playlist/play')
                      ? ''
                      : !sidebar
                        ? 'lg:pl-[16rem]'
                        : 'lg:pl-[5rem]'
                  } pt-[5rem] pb-10`}>
                  {children}
                </main>
              </div>
            ) : (
              <div className={`${pathname.includes('ceclips') && 'h-screen overflow-hidden'}`}>
                <div
                  className={`block py-4 fixed w-full top-0 z-[50] flex h-[5rem] items-center justify-between gap-x-4 transition bg-ceflix px-5 sm:gap-x-6`}>
                  <div className={`${search ? 'hidden' : 'flex'} md:flex flex-row w-full`}>
                    <div
                      className={`flex flex-row items-center ${
                        !sidebarVideo
                          ? 'lg:ml-[14.6rem]'
                          : !(pathname.includes('watch') || pathname.includes('playlist/play'))
                            ? 'lg:ml-[5rem]'
                            : 'lg:ml-[0rem]'
                      } gap-2 lg:gap-6 flex-1 ${!sidebar ? 'justify-end' : 'justify-between'}`}>
                      {/* Separator */}

                      {pathname.includes('watch') || pathname.includes('playlist/play') ? (
                        <div
                          className={`${!sidebarVideo && 'hidden'} flex flex-row items-center gap-4`}>
                          <div className="flex flex-row items-center space-x-4">
                            {sidebarVideo && (
                              <button
                                type="button"
                                className={`${sidebarVideo ? '' : 'px-6'} p-1 rounded-full`}
                                onClick={() => toggleSidebarVideo(!sidebarVideo)}>
                                <span className="sr-only">Open sidebar</span>
                                <Bars3Icon className="text-white h-6 w-6" aria-hidden="true" />
                              </button>
                            )}

                            <Link to="/">
                              <LazyLoadImage
                                src="/logo-light.png"
                                alt="Ceflix Logo 7"
                                className="w-24 md:w-32"
                              />
                            </Link>
                          </div>
                        </div>
                      ) : (
                        <>
                          <div className="hidden lg:block">
                            <div
                              className={`${!sidebar && 'hidden'} flex flex-row items-center gap-4`}>
                              <div className="flex flex-row items-center space-x-4">
                                {sidebar && (
                                  <button
                                    type="button"
                                    className={`${sidebar ? '' : 'px-6'} p-1 rounded-full`}
                                    onClick={() => toggleSidebar(!sidebar)}>
                                    <span className="sr-only">Open sidebar</span>
                                    <Bars3Icon className="text-white h-6 w-6" aria-hidden="true" />
                                  </button>
                                )}

                                <Link to="/">
                                  <LazyLoadImage
                                    src="/logo-light.png"
                                    alt="Ceflix Logo 8"
                                    className="w-24 md:w-32"
                                  />
                                </Link>
                              </div>
                            </div>
                          </div>

                          <div className="lg:hidden">
                            <div
                              className={`${!sidebarMobile && 'hidden'} flex flex-row items-center gap-4`}>
                              <div className="flex flex-row items-center space-x-4">
                                {sidebarMobile && (
                                  <button
                                    type="button"
                                    className={`${sidebarMobile ? '' : 'px-6'} p-1 rounded-full`}
                                    onClick={() => toggleSidebarMobile(!sidebarMobile)}>
                                    <span className="sr-only">Open sidebar</span>
                                    <Bars3Icon className="text-white h-6 w-6" aria-hidden="true" />
                                  </button>
                                )}

                                <Link to="/">
                                  <LazyLoadImage
                                    src="/logo-light.png"
                                    alt="Ceflix Logo 9"
                                    className="w-24 md:w-32"
                                  />
                                </Link>
                              </div>
                            </div>
                          </div>
                        </>
                      )}

                      <div className={`hidden md:block mx-4 w-full`}>
                        <SearchBar placeholder="Search" btn={setMenu} />
                      </div>

                      <div className="flex items-center justify-end w-40 lg:gap-x-6">
                        <div
                          className={`grid ${
                            user ? 'grid-cols-3 md:grid-cols-2' : 'grid-cols-1'
                          } gap-x-3 items-center`}>
                          {!loading && (
                            <>
                              <button
                                type="button"
                                onClick={() => setSearch(!search)}
                                className="md:hidden p-2.5 w-10 rounded-full text-white hover:text-gray-300">
                                <span className="sr-only">View notifications</span>
                                <MagnifyingGlassIcon className="" aria-hidden="true" />
                              </button>

                              {user ? (
                                <>
                                  <div className="">
                                    <Link to="/studio/content/upload" className="">
                                      <span className="sr-only">View notifications</span>
                                      <VideoCameraIcon
                                        className="p-2 rounded-md text-white w-10"
                                        aria-hidden="true"
                                      />
                                    </Link>
                                  </div>
                                  <Menu as="div" className="relative w-8">
                                    <Menu.Button className="flex items-center">
                                      <span className="sr-only">Open user menu</span>
                                      <LazyLoadImage
                                        className="w-9 rounded-md bg-gray-50"
                                        src={user.profile_pic === "http://d3c5pcohbexzc4.cloudfront.net/profile/avatar.png" ? '/images/avatar.png' : user.profile_pic}
                                        alt="profile pic"
                                      />
                                      {/* <span className="hidden lg:flex lg:items-center">
                        <span
                          className="ml-4 text-sm font-semibold leading-6 text-white"
                          aria-hidden="true"
                        >
                          {user.fname} {user.lname}
                        </span>
                        <ChevronDownIcon
                          className="ml-2 h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                      </span> */}
                                    </Menu.Button>
                                    <Transition
                                      as={Fragment}
                                      enter="transition ease-out duration-100"
                                      enterFrom="transform opacity-0 scale-95"
                                      enterTo="transform opacity-100 scale-100"
                                      leave="transition ease-in duration-75"
                                      leaveFrom="transform opacity-100 scale-100"
                                      leaveTo="transform opacity-0 scale-95">
                                      <Menu.Items className="absolute right-0 overflow-hidden z-10 mt-2.5 w-40 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                                        {userNavigation.map((item) => (
                                          <Menu.Item key={item.name}>
                                            {({ active }) => (
                                              <Link
                                                to={item.href}
                                                className={classNames(
                                                  active ? 'bg-gray-50' : '',
                                                  'block text-sm px-3 py-2 leading-6 text-gray-900'
                                                )}>
                                                {item.name}
                                              </Link>
                                            )}
                                          </Menu.Item>
                                        ))}
                                        <Menu.Item key="logoutBtn">
                                          {({ active }) => (
                                            <button
                                              className={classNames(
                                                active ? 'bg-gray-50' : '',
                                                'block text-left px-3 py-2 w-full text-sm leading-6 text-gray-900'
                                              )}
                                              onClick={() => Logout()}>
                                              Log Out
                                            </button>
                                          )}
                                        </Menu.Item>
                                      </Menu.Items>
                                    </Transition>
                                  </Menu>
                                </>
                              ) : (
                                <div className="flex space-x-4 text-xs">
                                  <Link
                                    to="/login"
                                    className="flex flex-row whitespace-nowrap items-center gap-x-2 text-white font-bold uppercase transition bg-red-700 hover:bg-red-600 px-3 py-2 rounded-md">
                                    <UserCircleIcon
                                      className="h-6 w-6 text-white"
                                      aria-hidden="true"
                                    />
                                    Sign In
                                  </Link>
                                  {/* <Link
                        to="/signup"
                        className="hidden lg:block bg-red-500 px-7 py-2 rounded-md"
                      >
                        Sign Up
                      </Link> */}
                                </div>
                              )}
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className={`${
                      search ? 'flex flex-row' : 'hidden'
                    } w-full gap-2 items-center md:hidden`}>
                    <button
                      type="button"
                      onClick={() => setSearch(!search)}
                      className={`${
                        search && 'block'
                      } md:hidden flex-1 p-2.5 rounded-full text-white hover:text-gray-300`}>
                      <span className="sr-only">View notifications</span>
                      <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                    </button>
                    <SearchBar placeholder="Search" btn={setMenu} />
                  </div>
                </div>

                <main
                  className={`${
                    pathname.includes('watch') || pathname.includes('playlist/play')
                      ? ''
                      : !sidebar
                        ? 'lg:pl-[16rem]'
                        : 'lg:pl-[5rem]'
                  } pt-[5rem] pb-10`}>
                  {children}
                </main>
              </div>
            )}
          </div>
        </div>
      )}

      {!isDesktop && (
        <div className="lg:hidden">
          <div
            className={`${pathname.includes('watch') || pathname.includes('playlist/play') ? 'lg:ml-0' : ''}`}>
            <Transition.Root show={sidebarMobileOpen} as={Fragment}>
              <Dialog as="div" className="relative z-50" onClose={setSidebarMobileOpen}>
                <Transition.Child
                  as={Fragment}
                  enter="transition-opacity ease-linear duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="transition-opacity ease-linear duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0">
                  <div className="fixed inset-0 bg-neutral-900/80" />
                </Transition.Child>

                <div className="fixed inset-0 flex">
                  <Transition.Child
                    as={Fragment}
                    enter="transition ease-in-out duration-300 transform"
                    enterFrom="-translate-x-full"
                    enterTo="translate-x-0"
                    leave="transition ease-in-out duration-300 transform"
                    leaveFrom="translate-x-0"
                    leaveTo="-translate-x-full">
                    <Dialog.Panel className="relative mr-16 flex w-full max-w-[18rem] flex-1">
                      <Transition.Child
                        as={Fragment}
                        enter="ease-in-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in-out duration-300"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0">
                        <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                          <button
                            type="button"
                            className="-m-2.5 p-2.5"
                            onClick={() => setSidebarMobileOpen(true)}>
                            <span className="sr-only">Close sidebar</span>
                            <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
                          </button>
                        </div>
                      </Transition.Child>
                      {/* Sidebar component, swap this element with another sidebar if you like */}
                      <div className="flex grow flex-col gap-y-5 z-50 overflow-y-auto px-6 pb-4 ring-1 ring-white/10">
                        <Link to="/" className="flex mt-4 h-14 shrink-0 items-center">
                          <LazyLoadImage
                            src="/logo-light.png"
                            alt="Ceflix Logo 10"
                            className="w-24 md:w-32"
                          />
                        </Link>
                        <nav className="flex flex-1 flex-col">
                          <div className="flex flex-1 flex-col gap-y-7">
                            <ul role="list" className="-mx-2">
                              <li className="divide-y divide-neutral-800">
                                <div className="pb-2 space-y-1">
                                  {navigation.map((item) => (
                                    <div key={item.name}>
                                      <Link
                                        to={item.href}
                                        onClick={() => toggleSidebarMobile(!sidebarMobile)}
                                        className={`${
                                          pathname.includes(item.href)
                                            ? 'bg-[#111]'
                                            : 'hover:bg-neutral-800 rounded-md'
                                        }
                            'group text-gray-200 text-sm flex flex-row gap-x-5 rounded-md p-1.5 leading-6 font-semibold'
                          `}>
                                        <FontAwesomeIcon
                                          icon={faCompass}
                                          className={`${
                                            pathname.includes(item.href)
                                              ? 'bg-[#111]'
                                              : 'hover:bg-neutral-800 rounded-md'
                                          } h-6 w-6`}
                                          aria-hidden="true"
                                        />
                                        {item.name}
                                      </Link>
                                    </div>
                                  ))}
                                </div>
                              </li>
                            </ul>
                          </div>
                        </nav>
                      </div>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </Dialog>
            </Transition.Root>

            {/* Static sidebar for desktop */}
            {(pathname.includes('watch') || pathname.includes('playlist/play')) && (
              <div
                className={`divide-y ${
                  sidebarMobile ? 'w-[5rem] hidden' : 'w-[16rem]'
                } bg-ceflix h-full ease-linear z-[60]  border-neutral-200/5 lg:fixed lg:inset-y-0 lg:flex-col ${
                  (pathname.includes('watch') || pathname.includes('playlist/play')) &&
                  sidebarMobile
                    ? 'lg:hidden'
                    : 'fixed block lg:flex'
                }`}>
                {/* desktop Sidebar component, swap this element with another sidebar if you like */}
                <div className="flex grow nav-wrapper flex-col gap-y-5 px-5 pt-1">
                  <nav
                    className={`${
                      !sidebarMobile ? 'w-[16rem]' : 'w-[5rem]'
                    } flex nav-sidebar h-full left-0 flex-1 flex-col pt-1`}>
                    <div className="flex flex-1 text-center items-center flex-col mt-1">
                      <div
                        className={`${sidebarMobile ? 'justify-center' : ''} flex flex-row w-full`}>
                        <button
                          type="button"
                          className={`${sidebarMobile ? '' : 'px-6'} p-2 rounded-full`}
                          onClick={() => toggleSidebarMobile(!sidebarMobile)}>
                          <span className="sr-only">Open sidebar</span>
                          <Bars3Icon className="text-white h-6 w-6" aria-hidden="true" />
                        </button>
                        <Link to="/">
                          <LazyLoadImage
                            src="/logo-light.png"
                            className={`${!sidebarMobile ? 'black' : 'hidden'} w-24`}
                            alt="Ceflix Logo 11"
                          />
                        </Link>
                      </div>
                      <div className={`w-full space-y-4 mt-6 ${!sidebarMobile && 'px-3'}`}>
                        <div className="divide-y-2 divide-neutral-200/5">
                          <ul
                            role="list"
                            className={`${sidebarMobile ? 'p-[.4rem] space-y-4' : 'pr-4 w-full'} mb-4`}>
                            {navigation.map((item) => (
                              <li className="" key={item.name}>
                                <Link
                                  to={item.href}
                                  onClick={() => toggleSidebarMobile(!sidebarMobile)}
                                  className={`w-auto ${
                                    (
                                      item.href !== '/'
                                        ? pathname.includes(item.href) && item.href !== '/'
                                        : pathname === '/' && true
                                    )
                                      ? 'text-white'
                                      : 'text-white'
                                  }
                              group flex  ${
                                sidebarMobile
                                  ? 'w-24 flex-col text-xxxs items-center rounded-md'
                                  : 'flex-row items-center font-medium rounded-lg tracking-wider'
                              } hover:bg-neutral-700 transition p-3
                          `}>
                                  <FontAwesomeIcon
                                    icon={item.icon}
                                    className={`${pathname === item.href && 'text-red-600'} text-xl`}
                                    aria-hidden="true"
                                  />
                                  <span
                                    className={`text-xs ${sidebarMobile ? 'text-xxxs mt-2' : 'ml-4'}`}>
                                    {item.name}
                                  </span>
                                </Link>
                              </li>
                            ))}
                            {sidebarMobile && (
                              <li className="" key="library">
                                <Link
                                  to="/library"
                                  className={`w-auto text-white
                              group flex  ${
                                sidebarMobile
                                  ? 'w-24 flex-col text-xxxs items-center rounded-md'
                                  : 'flex-row items-center font-medium rounded-lg tracking-wider'
                              } hover:bg-neutral-700 transition p-3
                          `}>
                                  <FontAwesomeIcon
                                    icon={faFolder}
                                    className={`${pathname === 'library' && 'text-red-600'} text-xl`}
                                    aria-hidden="true"
                                  />
                                  <span
                                    className={`text-xs ${sidebarMobile ? 'text-xxxs mt-2' : 'ml-4'}`}>
                                    Library
                                  </span>
                                </Link>
                              </li>
                            )}
                          </ul>

                          {!sidebarMobile && (
                            <>
                              <ul
                                role="list"
                                className={`${sidebarMobile ? 'p-[.4rem] space-y-7' : 'pr-4 w-full'} pt-2`}>
                                {navigation2.map((item) => (
                                  <li className="" key={item.name}>
                                    <Link
                                      to={item.href}
                                      onClick={() => toggleSidebarMobile(!sidebar)}
                                      className={`w-auto ${
                                        (
                                          item.href !== '/'
                                            ? pathname.includes(item.href) && item.href !== '/'
                                            : pathname === '/' && true
                                        )
                                          ? 'text-white'
                                          : 'text-white'
                                      }
                              group flex p-3  ${
                                sidebarMobile
                                  ? 'w-24 flex-col text-xxxs items-center rounded-md'
                                  : 'flex-row items-center font-medium rounded-lg tracking-wider hover:bg-neutral-700 transition'
                              }
                          `}>
                                      <FontAwesomeIcon
                                        icon={item.icon}
                                        className={`${
                                          pathname.includes(item.href) && 'text-red-600'
                                        } text-md mr-4`}
                                        aria-hidden="true"
                                      />
                                      <span className="text-xs">{item.name}</span>
                                    </Link>
                                  </li>
                                ))}
                              </ul>
                            </>
                          )}
                        </div>

                        {!sidebarMobile && (
                          <>
                            <h1 className="text-white text-xxs uppercase text-left pl-3">
                              Subscriptions
                            </h1>
                            <ul
                              role="list"
                              className={`${sidebarMobile ? 'p-[.4rem] space-y-7' : 'pr-4 w-full'}`}>
                              {subscriptions.slice(0, subscriptionsLen).map((item) => (
                                <li className="" key={item.channel}>
                                  <Link
                                    to={`/channel/${item.id}`}
                                    onClick={() => toggleSidebarMobile(!sidebarMobile)}
                                    className={`w-auto text-white group flex p-3 ${
                                      sidebarMobile
                                        ? 'w-24 flex-col text-xxxs items-center rounded-md'
                                        : 'flex-row items-center font-medium rounded-lg tracking-wider hover:bg-neutral-700 transition'
                                    }
                          `}>
                                    <LazyLoadImage
                                      src={item.url}
                                      className="object-cover w-[1.4rem] h-[1.4rem] mr-2 rounded-full"
                                    />
                                    <span className="text-xs">
                                      {truncate(capitalize(item.channel), 14)}
                                    </span>
                                  </Link>
                                </li>
                              ))}
                              {(JSON.parse(localStorage.getItem('ceflix.subscriptions')) || '') && (
                                <button
                                  className="w-full px-3 text-left text-xs py-2 text-white font-medium rounded-md tracking-wider hover:bg-neutral-700 transition"
                                  onClick={() => toggleSubscriptions()}>
                                  {toggle ? 'Show More' : 'Show Less'}
                                </button>
                              )}
                            </ul>
                          </>
                        )}

                        {!sidebarMobile && (
                          <>
                            <h1 className="text-white text-xxs uppercase text-left pl-3">
                              More from ceflix
                            </h1>
                            <ul
                              role="list"
                              className={`${sidebarMobile ? 'p-[.4rem] space-y-7' : 'pr-4 w-full'} mb-4`}>
                              {more.map((item) => (
                                <li className="" key={item.name}>
                                  <Link
                                    onClick={() => setSidebarMobileOpen(false)}
                                    to={item.href}
                                    className={`w-auto ${
                                      (
                                        item.href !== '/'
                                          ? pathname.includes(item.href) && item.href !== '/'
                                          : pathname === '/' && true
                                      )
                                        ? 'text-white'
                                        : 'text-white'
                                    }
                              group flex p-3  ${
                                sidebarMobile
                                  ? 'w-24 flex-col text-xxxs items-center rounded-full'
                                  : 'flex-row items-center font-medium rounded-lg tracking-wider hover:bg-neutral-700 transition'
                              }
                          `}>
                                    <item.icon className="w-6 text-white mr-2" aria-hidden="true" />
                                    {/* <FontAwesomeIcon icon={item.icon} className={`${pathname.includes(item.href)
                                          && "text-red-600"
                                        } text-lg mr-4`} aria-hidden="true"  aria-hidden="true" /> */}
                                    <span className="text-xs">{item.name}</span>
                                  </Link>
                                </li>
                              ))}
                            </ul>
                          </>
                        )}
                      </div>
                    </div>
                  </nav>
                </div>
              </div>
            )}

            {(pathname.includes('watch') || pathname.includes('playlist/play')) ? (
              <div className={`${pathname.includes('ceclips') && 'h-screen overflow-hidden'}`}>
                <div
                  className={`block py-4 fixed w-full top-0 z-[50] flex h-[5rem] items-center justify-between gap-x-4 transition bg-ceflix px-5 sm:gap-x-6`}>
                  <div className={`${search ? 'hidden' : 'flex'} md:flex flex-row w-full`}>
                    <div
                      className={`flex flex-row items-center ${
                        !sidebarMobile
                          ? 'lg:ml-[14.6rem]'
                          : !(pathname.includes('watch') || pathname.includes('playlist/play'))
                            ? 'lg:ml-[5rem]'
                            : 'lg:ml-[0rem]'
                      } gap-2 lg:gap-6 flex-1 ${!sidebarMobile ? 'justify-end' : 'justify-between'}`}>
                      {/* Separator */}

                      {pathname.includes('watch') || pathname.includes('playlist/play') ? (
                        <div
                          className={`${!sidebarMobile && 'hidden'} flex flex-row items-center gap-4`}>
                          <div className="flex flex-row items-center space-x-4">
                              <button
                                type="button"
                                className={`${sidebarMobile ? '' : 'px-6'} p-1 rounded-full`}
                                onClick={() => {toggleSidebarMobile(!sidebarMobile); toggleSidebarVideo(!sidebarVideo)}}>
                                <span className="sr-only">Open sidebar</span>
                                <Bars3Icon className="text-white h-6 w-6" aria-hidden="true" />
                              </button>

                            <Link to="/">
                              <LazyLoadImage
                                src="/logo-light.png"
                                alt="Ceflix Logo 12"
                                className="w-24 md:w-32"
                              />
                            </Link>
                          </div>
                        </div>
                      ) : (
                        <div
                          className={`${!sidebarMobile && 'hidden'} flex flex-row items-center gap-4`}>
                          <div className="flex flex-row items-center space-x-4">
                            {sidebarMobile && (
                              <button
                                type="button"
                                className={`${sidebarMobile ? 'lg:hidden' : 'px-6'} p-1 rounded-full`}
                                onClick={() => {toggleSidebarMobile(!sidebarMobile); toggleSidebarVideo(!sidebarVideo)}}>
                                <span className="sr-only">Open sidebar</span>
                                <Bars3Icon className="text-white h-6 w-6" aria-hidden="true" />
                              </button>
                            )}

                            <Link to="/">
                              <LazyLoadImage
                                src="/logo-light.png"
                                alt="Ceflix Logo 13"
                                className="w-24 md:w-32"
                              />
                            </Link>
                          </div>
                        </div>
                      )}

                      <div className={`hidden md:block mx-4 w-full`}>
                        <SearchBar placeholder="Search" btn={setMenu} />
                      </div>

                      <div className="flex items-center justify-end w-40 lg:gap-x-6">
                        <div className={`flex flex-row space-x-3 items-center`}>
                          {!loading && (
                            <>
                              <button
                                type="button"
                                onClick={() => setSearch(!search)}
                                className="md:hidden p-2.5 w-10 rounded-full text-white hover:text-gray-300">
                                <span className="sr-only">View notifications</span>
                                <MagnifyingGlassIcon className="" aria-hidden="true" />
                              </button>

                              {user ? (
                                <>
                                  <div className="">
                                    <Link to="/studio/content/upload" className="">
                                      <span className="sr-only">View notifications</span>
                                      <VideoCameraIcon
                                        className="p-2 rounded-md text-white w-10"
                                        aria-hidden="true"
                                      />
                                    </Link>
                                  </div>
                                  <Menu as="div" className="relative w-8">
                                    <Menu.Button className="flex items-center">
                                      <span className="sr-only">Open user menu</span>
                                      <LazyLoadImage
                                        className="w-9 rounded-md bg-gray-50"
                                        src={user.profile_pic === "http://d3c5pcohbexzc4.cloudfront.net/profile/avatar.png" ? '/images/avatar.png' : user.profile_pic}
                                        alt="profile pic"
                                      />
                                      {/* <span className="hidden lg:flex lg:items-center">
                        <span
                          className="ml-4 text-sm font-semibold leading-6 text-white"
                          aria-hidden="true"
                        >
                          {user.fname} {user.lname}
                        </span>
                        <ChevronDownIcon
                          className="ml-2 h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                      </span> */}
                                    </Menu.Button>
                                    <Transition
                                      as={Fragment}
                                      enter="transition ease-out duration-100"
                                      enterFrom="transform opacity-0 scale-95"
                                      enterTo="transform opacity-100 scale-100"
                                      leave="transition ease-in duration-75"
                                      leaveFrom="transform opacity-100 scale-100"
                                      leaveTo="transform opacity-0 scale-95">
                                      <Menu.Items className="absolute right-0 overflow-hidden z-10 mt-2.5 w-40 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                                        {userNavigation.map((item) => (
                                          <Menu.Item key={item.name}>
                                            {({ active }) => (
                                              <Link
                                                onClick={() => setSidebarMobileOpen(false)}
                                                to={item.href}
                                                className={classNames(
                                                  active ? 'bg-gray-50' : '',
                                                  'block text-sm px-3 py-2 leading-6 text-gray-900'
                                                )}>
                                                {item.name}
                                              </Link>
                                            )}
                                          </Menu.Item>
                                        ))}
                                        <Menu.Item key="logoutBtn">
                                          {({ active }) => (
                                            <button
                                              className={classNames(
                                                active ? 'bg-gray-50' : '',
                                                'block text-left px-3 py-2 w-full text-sm leading-6 text-gray-900'
                                              )}
                                              onClick={() => Logout()}>
                                              Log Out
                                            </button>
                                          )}
                                        </Menu.Item>
                                      </Menu.Items>
                                    </Transition>
                                  </Menu>
                                </>
                              ) : (
                                <div className="flex space-x-4 text-xs">
                                  <Link
                                    to="/login"
                                    className="flex flex-row whitespace-nowrap items-center gap-x-2 text-white font-bold uppercase transition bg-red-700 hover:bg-red-600 px-3 py-2 rounded-md">
                                    <UserCircleIcon
                                      className="h-6 w-6 text-white"
                                      aria-hidden="true"
                                    />
                                    Sign In
                                  </Link>
                                  {/* <Link
                        to="/signup"
                        className="hidden lg:block bg-red-500 px-7 py-2 rounded-md"
                      >
                        Sign Up
                      </Link> */}
                                </div>
                              )}
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className={`${
                      search ? 'flex flex-row' : 'hidden'
                    } w-full gap-2 items-center md:hidden`}>
                    <button
                      type="button"
                      onClick={() => setSearch(!search)}
                      className={`${
                        search && 'block'
                      } md:hidden flex-1 p-2.5 rounded-full text-white hover:text-gray-300`}>
                      <span className="sr-only">View notifications</span>
                      <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                    </button>
                    <SearchBar placeholder="Search" btn={setMenu} />
                  </div>
                </div>

                <main
                  className={`${
                    pathname.includes('watch') || pathname.includes('playlist/play')
                      ? ''
                      : !sidebarMobile
                        ? 'lg:pl-[16rem]'
                        : 'lg:pl-[5rem]'
                  } pt-[5rem] pb-10`}>
                  {children}
                </main>
              </div>
            ) : (
              <div className={`${pathname.includes('ceclips') && 'h-screen overflow-hidden'}`}>
                <div
                  className={`block py-4 fixed w-full top-0 z-[50] flex h-[5rem] items-center justify-between gap-x-4 transition bg-ceflix px-5 sm:gap-x-6`}>
                  <div className={`${search ? 'hidden' : 'flex'} md:flex flex-row w-full`}>
                    <div
                      className={`flex flex-row items-center ${
                        !sidebarVideo
                          ? 'lg:ml-[14.6rem]'
                          : !(pathname.includes('watch') || pathname.includes('playlist/play'))
                            ? 'lg:ml-[5rem]'
                            : 'lg:ml-[0rem]'
                      } gap-2 lg:gap-6 flex-1 ${!sidebarMobile ? 'justify-end' : 'justify-between'}`}>
                      {/* Separator */}

                      {pathname.includes('watch') || pathname.includes('playlist/play') ? (
                        <div
                          className={`${!sidebarVideo && 'hidden'} flex flex-row items-center gap-4`}>
                          <div className="flex flex-row items-center space-x-4">
                            {sidebarVideo && (
                              <button
                                type="button"
                                className={`${sidebarVideo ? '' : 'px-6'} p-1 rounded-full`}
                                onClick={() => toggleSidebarVideo(!sidebarVideo)}>
                                <span className="sr-only">Open sidebar</span>
                                <Bars3Icon className="text-white h-6 w-6" aria-hidden="true" />
                              </button>
                            )}

                            <Link to="/">
                              <LazyLoadImage
                                src="/logo-light.png"
                                alt="Ceflix Logo 14"
                                className="w-24 md:w-32"
                              />
                            </Link>
                          </div>
                        </div>
                      ) : (
                        <div
                          className={`${!sidebarMobile && 'hidden'} flex flex-row items-center gap-4`}>
                          <div className="flex flex-row items-center space-x-4">
                            {sidebarMobile && (
                              <button
                                type="button"
                                className={`${sidebarMobile ? '' : 'px-6'} p-1 rounded-full`}
                                onClick={() => toggleSidebar(!sidebar)}>
                                <span className="sr-only">Open sidebar</span>
                                <Bars3Icon className="text-white h-6 w-6" aria-hidden="true" />
                              </button>
                            )}

                            <Link to="/">
                              <LazyLoadImage
                                src="/logo-light.png"
                                alt="Ceflix Logo 1"
                                className="w-24 md:w-32"
                              />
                            </Link>
                          </div>
                        </div>
                      )}

                      <div className={`hidden md:block mx-4 w-full`}>
                        <SearchBar placeholder="Search" btn={setMenu} />
                      </div>

                      <div className="flex items-center justify-end w-40 lg:gap-x-6">
                        <div
                          className={`grid ${
                            user ? 'grid-cols-3 md:grid-cols-2' : 'grid-cols-1'
                          } gap-x-3 items-center`}>
                          {!loading && (
                            <>
                              <button
                                type="button"
                                onClick={() => setSearch(!search)}
                                className="md:hidden p-2.5 w-10 rounded-full text-white hover:text-gray-300">
                                <span className="sr-only">View notifications</span>
                                <MagnifyingGlassIcon className="" aria-hidden="true" />
                              </button>

                              {user ? (
                                <>
                                  <div className="">
                                    <Link href="/studio/content/upload" className="">
                                      <span className="sr-only">View notifications</span>
                                      <VideoCameraIcon
                                        className="p-2 rounded-md text-white w-10"
                                        aria-hidden="true"
                                      />
                                    </Link>
                                  </div>
                                  <Menu as="div" className="relative w-8">
                                    <Menu.Button className="flex items-center">
                                      <span className="sr-only">Open user menu</span>
                                      <LazyLoadImage
                                        className="w-9 rounded-md bg-gray-50"
                                        src={user.profile_pic === "http://d3c5pcohbexzc4.cloudfront.net/profile/avatar.png" ? '/images/avatar.png' : user.profile_pic}
                                        alt="profile pic"
                                      />
                                      {/* <span className="hidden lg:flex lg:items-center">
                        <span
                          className="ml-4 text-sm font-semibold leading-6 text-white"
                          aria-hidden="true"
                        >
                          {user.fname} {user.lname}
                        </span>
                        <ChevronDownIcon
                          className="ml-2 h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                      </span> */}
                                    </Menu.Button>
                                    <Transition
                                      as={Fragment}
                                      enter="transition ease-out duration-100"
                                      enterFrom="transform opacity-0 scale-95"
                                      enterTo="transform opacity-100 scale-100"
                                      leave="transition ease-in duration-75"
                                      leaveFrom="transform opacity-100 scale-100"
                                      leaveTo="transform opacity-0 scale-95">
                                      <Menu.Items className="absolute right-0 overflow-hidden z-10 mt-2.5 w-40 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                                        {userNavigation.map((item) => (
                                          <Menu.Item key={item.name}>
                                            {({ active }) => (
                                              <Link
                                                to={item.href}
                                                className={classNames(
                                                  active ? 'bg-gray-50' : '',
                                                  'block text-sm px-3 py-2 leading-6 text-gray-900'
                                                )}>
                                                {item.name}
                                              </Link>
                                            )}
                                          </Menu.Item>
                                        ))}
                                        <Menu.Item key="logoutBtn">
                                          {({ active }) => (
                                            <button
                                              className={classNames(
                                                active ? 'bg-gray-50' : '',
                                                'block text-left px-3 py-2 w-full text-sm leading-6 text-gray-900'
                                              )}
                                              onClick={() => Logout()}>
                                              Log Out
                                            </button>
                                          )}
                                        </Menu.Item>
                                      </Menu.Items>
                                    </Transition>
                                  </Menu>
                                </>
                              ) : (
                                <div className="flex space-x-4 text-xs">
                                  <Link
                                    to="/login"
                                    className="flex flex-row whitespace-nowrap items-center gap-x-2 text-white font-bold uppercase transition bg-red-700 hover:bg-red-600 px-3 py-2 rounded-md">
                                    <UserCircleIcon
                                      className="h-6 w-6 text-white"
                                      aria-hidden="true"
                                    />
                                    Sign In
                                  </Link>
                                  {/* <Link
                        href="/signup"
                        className="hidden lg:block bg-red-500 px-7 py-2 rounded-md"
                      >
                        Sign Up
                      </Link> */}
                                </div>
                              )}
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className={`${
                      search ? 'flex flex-row' : 'hidden'
                    } w-full gap-2 items-center md:hidden`}>
                    <button
                      type="button"
                      onClick={() => setSearch(!search)}
                      className={`${
                        search && 'block'
                      } md:hidden flex-1 p-2.5 rounded-full text-white hover:text-gray-300`}>
                      <span className="sr-only">View notifications</span>
                      <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                    </button>
                    <SearchBar placeholder="Search" btn={setMenu} />
                  </div>
                </div>

                <main
                  className={`${
                    pathname.includes('watch') || pathname.includes('playlist/play')
                      ? ''
                      : !sidebarMobile
                        ? 'lg:pl-[16rem]'
                        : 'lg:pl-[5rem]'
                  } pt-[5rem] pb-10`}>
                  {children}
                </main>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default Navigation;
